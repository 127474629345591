<template>
    <div>
        <ValidationObserver ref="formModalValidate">
            <b-row>
                <b-col cols="12" >
                    <b-form-group :label="$t('term')">
                        <ValidationProvider name="term" rules="required" v-slot="{valid, errors}">
                            <b-input-group class="mb-1"
                                           :class="errors[0] ? 'box-border-color-for-input-group' : ''"
                            >
                                <b-form-input v-model="formData.term"/>
                            </b-input-group>
                            <b-form-invalid-feedback v-if="errors[0]" v-html="errors[0]" class="mb-2"/>
                        </ValidationProvider>
                    </b-form-group>
                </b-col>
                <b-col cols="12" >
                    <b-form-group :label="$t('definition')">
                        <ValidationProvider name="definition" rules="required" v-slot="{valid, errors}">
                            <b-input-group class="mb-1"
                                           :class="errors[0] ? 'box-border-color-for-input-group' : ''"
                            >
                                <b-form-input v-model="formData.definition"/>
                            </b-input-group>
                            <b-form-invalid-feedback v-if="errors[0]" v-html="errors[0]" class="mb-2"/>
                        </ValidationProvider>
                    </b-form-group>
                </b-col>
                <b-col cols="12">
                    <b-form-group :label="$t('reference')">
                        <ValidationProvider name="reference" rules="required" v-slot="{valid, errors}">
                            <b-input-group class="mb-1"
                                           :class="errors[0] ? 'box-border-color-for-input-group' : ''"
                            >
                                <b-form-input v-model="formData.reference"/>
                            </b-input-group>
                            <b-form-invalid-feedback v-if="errors[0]" v-html="errors[0]" class="mb-2"/>
                        </ValidationProvider>
                    </b-form-group>
                </b-col>
            </b-row>
            <div class="d-flex justify-content-center mt-2">
                <b-button variant="primary"
                          @click="updateForm"
                          :disabled="formLoading">
                    {{ $t('save') }}
                </b-button>
            </div>
        </ValidationObserver>
    </div>
</template>

<script>
    // Other
    import {ValidationObserver, ValidationProvider} from 'vee-validate'
    import GlossaryServices from "@/services/GlossaryServices";

    export default {
        components: {
            ValidationProvider,
            ValidationObserver
        },
        props: {
            formId: {
                type: String
            }
        },
        data() {
            return {
                formData: {
                },
                formLoading: false
            }
        },
        watch: {
            formId: function(val) {
                this.get(val)
            }
        },
        async created() {
            await this.get(this.formId)
        },
        methods: {
            get(id) {
                GlossaryServices.get(id)
                                  .then(response => {
                                      let data = response.data.data;
                                      this.formData = data
                                  })
                                  .catch(e => {
                                      this.showErrors(e);
                                  })
            },
            async updateForm() {
                const isValid = await this.$refs.formModalValidate.validate();
                if (isValid && this.formId) {
                    this.formLoading = true;
                    GlossaryServices.update(this.formId, this.formData)
                                      .then(response => {
                                          this.$emit('updateFormSuccess', true);
                                          this.$toast.success(this.$t('api.' + response.data.message));
                                      })
                                      .catch(e => {
                                          this.showErrors(e, this.$refs.formModalValidate);
                                      })
                                      .finally(() => {
                                          this.formLoading = false
                                      })
                }
            }
        }
    }
</script>
