<template>
    <div>
        <ValidationObserver ref="formModalValidate">
            <b-row>
                    <b-col cols="12" >
                        <b-form-group :label="$t('term')">
                            <ValidationProvider name="term" rules="required" v-slot="{valid, errors}">
                                <b-input-group class="mb-1"
                                               :class="errors[0] ? 'box-border-color-for-input-group' : ''"
                                >
                                    <b-form-input v-model="formData.term"/>
                                </b-input-group>
                                <b-form-invalid-feedback v-if="errors[0]" v-html="errors[0]" class="mb-2"/>
                            </ValidationProvider>
                        </b-form-group>
                    </b-col>
                    <b-col cols="12" >
                        <b-form-group :label="$t('definition')">
                            <ValidationProvider name="definition" rules="required" v-slot="{valid, errors}">
                                <b-input-group class="mb-1"
                                               :class="errors[0] ? 'box-border-color-for-input-group' : ''"
                                >
                                    <b-form-input v-model="formData.definition"/>
                                </b-input-group>
                                <b-form-invalid-feedback v-if="errors[0]" v-html="errors[0]" class="mb-2"/>
                            </ValidationProvider>
                        </b-form-group>
                    </b-col>
                    <b-col cols="12">
                        <b-form-group :label="$t('reference')">
                            <ValidationProvider name="reference" rules="required" v-slot="{valid, errors}">
                                <b-input-group class="mb-1"
                                               :class="errors[0] ? 'box-border-color-for-input-group' : ''"
                                >
                                    <b-form-input v-model="formData.reference"/>
                                </b-input-group>
                                <b-form-invalid-feedback v-if="errors[0]" v-html="errors[0]" class="mb-2"/>
                            </ValidationProvider>
                        </b-form-group>
                    </b-col>
            </b-row>
            <div class="d-flex justify-content-center mt-2">
                <b-button variant="primary"
                          @click="createForm"
                          :disabled="formLoading">
                    {{ $t('save') }}
                </b-button>
            </div>
        </ValidationObserver>
    </div>
</template>

<script>
    // Components
    import FacultySelectbox from '@/components/interactive-fields/FacultySelectbox.vue';
    import StaffAutoComplete from '@/components/interactive-fields/StaffAutoComplete.vue';
    import StatusSelectbox from '@/components/interactive-fields/StatusSelectbox.vue';
    // Other
    import {ValidationObserver, ValidationProvider} from 'vee-validate'
    import GlossaryServices from "@/services/GlossaryServices";

    export default {
        components: {
            FacultySelectbox,
            StaffAutoComplete,
            StatusSelectbox,
            ValidationProvider,
            ValidationObserver
        },
        data() {
            return {
                formData: {},
                formLoading: false
            }
        },
        methods: {
            async createForm() {
                const isValid = await this.$refs.formModalValidate.validate();
                if (isValid) {
                    this.formLoading = true;
                    GlossaryServices.store(this.formData)
                                      .then(response => {
                                          this.$emit('createFormSuccess', true);
                                          this.$toast.success(this.$t('api.' + response.data.message));
                                      })
                                      .catch(e => {
                                          this.showErrors(e, this.$refs.formModalValidate);
                                      })
                                      .finally(() => {
                                          this.formLoading = false
                                      })
                }
            }
        }
    }
</script>

